import {Modal} from 'react-bootstrap'

export const UserCreditWarningModal = ({show, onHide}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>Credits missing!</Modal.Header>
      <Modal.Body
        style={{display: 'flex', flexDirection: 'column', textAlign: 'center'}}
      >
        <small>
          Seems you are out of credits. Please reach out to your respective sales
          representative.
        </small>
      </Modal.Body>
    </Modal>
  )
}
