import {useQuery, useMutation, useQueryClient} from '@tanstack/react-query'
import * as userApi from '../../services/userApi.js'
import {useNavigate} from 'react-router-dom'
import {errorToast, successToast} from '../../components/atoms/Toasts.js'
import {
  setPersistence,
  browserLocalPersistence,
  onIdTokenChanged,
  signOut,
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail
} from 'firebase/auth'
import {useState, useEffect} from 'react'
import {useResetRecoil} from '../../contexts/recoilResetContext.js'

export const useFirebaseUserId = () => {
  const {data, isLoading, isError} = useQuery({
    queryKey: ['firebaseUserId'],
    queryFn: () => {
      const auth = getAuth()
      const user = auth.currentUser
      if (!user) throw new Error('No user logged in')
      return user.uid
    },
    meta: {
      errorMessage: 'Fetching User ID Failed.\nPlease try again later.'
    }
  })
  return {data, isLoading, isError}
}
export const useGetUserIdentity = ({params = {}, enabled = true}) => {
  return useQuery({
    queryKey: ['userIdentity', params],
    queryFn: () => userApi.getUserIdentity({params}),
    staleTime: 15 * 60 * 1000,
    cacheTime: 59 * 60 * 1000,
    enabled: !!enabled,
    meta: {
      errorMessage: 'Fetching User Inforation Failed.\nPlease try again later.'
    }
  })
}
export const useGetUserCredit = () => {
  return useQuery({
    queryKey: ['userCredit'],
    queryFn: () => userApi.getUserCredit(),
    staleTime: 15 * 60 * 1000,
    cacheTime: 60 * 60 * 1000,
    meta: {
      errorMessage: 'Fetching Credit Inforation Failed.\nPlease try again later.'
    }
  })
}
export const useGetUserProfile = () => {
  return useQuery({
    queryKey: ['userProfile'],
    queryFn: () => userApi.getUserProfile(),
    staleTime: 8 * 60 * 60 * 1000,
    cacheTime: 7 * 24 * 60 * 60 * 1000,
    meta: {
      errorMessage: 'Fetching Profile Inforation Failed.\nPlease try again later.'
    }
  })
}
export const usePatchUserProfile = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({payload}) => userApi.patchUserProfile({payload}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['userProfile']})
      successToast('Profile Information Updated!')
    },
    onError: () => {
      errorToast('Updating Profile Information Failed.\nPlease try again later.')
    }
  })
}
export const useGetUserAccessToken = ({params = {}}) => {
  return useQuery({
    queryKey: ['userAccessToken', params],
    queryFn: () => userApi.getUserAccessToken({params}),
    staleTime: 15 * 60 * 1000,
    cacheTime: 7 * 24 * 60 * 60 * 1000,
    meta: {
      errorMessage: 'Connecting To Email Provider Failed.\nPlease try again later.'
    }
  })
}
export const useGetUserThirdPartyProvisions = () => {
  return useQuery({
    queryKey: ['userThirdPartyProvisions'],
    queryFn: () => userApi.getUserThirdPartyProvisions(),
    staleTime: 6 * 24 * 60 * 60 * 1000,
    cacheTime: 7 * 24 * 60 * 60 * 1000,
    meta: {
      errorMessage: 'Connecting To Email Provider Failed.\nPlease try again later.'
    }
  })
}
export const usePutUserThirdPartyProvisions = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const oAuthRedirectUri = window.location.origin + '/oauth-callback'
  return useMutation({
    mutationFn: ({authorization_code, provider, scope}) =>
      userApi.putUserThirdPartyProvisions({
        payload: {
          authorization_code,
          provider,
          redirect_uri: oAuthRedirectUri,
          scope
        }
      }),
    onSuccess: (response) => {
      queryClient.invalidateQueries({queryKey: ['userThirdPartyProvisions']})
      queryClient.invalidateQueries({queryKey: ['userAccessToken']})
      successToast(
        `Account Connected!\nYour ${response?.provider} account has been connected successfully.`
      )
      navigate('/profile')
    },
    onError: () => {
      errorToast('Connecting Account Failed.\nPlease try again later.')
    }
  })
}

export const useSendPasswordResetEmail = () => {
  const navigate = useNavigate()
  return useMutation({
    mutationFn: ({emailAddress}) => sendPasswordResetEmail(getAuth(), emailAddress),
    onSuccess: () => {
      successToast('Password Reset Link Sent!\nPlease Check your mail inbox.', 30000)
      navigate('/sign-in')
    },
    onError: () => {
      errorToast('Resetting Password Failed.\nPlease try again later.')
    }
  })
}

export const usePostUserWithEmailAndPassword = () => {
  const navigate = useNavigate()
  return useMutation({
    mutationFn: async ({emailAddress, password}) => {
      const userCredential = await createUserWithEmailAndPassword(
        getAuth(),
        emailAddress,
        password
      )
      await sendEmailVerification(userCredential.user)
      return userCredential
    },
    onSuccess: () => {
      successToast('Verification Email Sent!\nPlease Check your mail inbox.', 30000)
      navigate('/sign-in')
    },
    onError: () => {
      errorToast('Sign Up Failed.\nPlease try again later.')
    }
  })
}

export const useSignInWithEmailAndPassword = () => {
  return useMutation({
    mutationFn: async ({payload}) => {
      const auth = getAuth()
      await setPersistence(auth, browserLocalPersistence).catch((error) => {
        console.error('Error setting persistence:', error)
        throw error
      })
      return await signInWithEmailAndPassword(
        auth,
        payload.emailAddress,
        payload.password
      )
    },
    onSuccess: (userCredential) => {
      if (!userCredential.user.emailVerified) {
        errorToast(
          'Email Verification Required.\nPlease verify your email before proceeding.'
        )
        throw Error
      }
    },
    onError: () => {
      errorToast('Logging In Failed.\nPlease try again later.')
    }
  })
}

export const useFirebaseAuth = () => {
  const [currentUser, setCurrentUser] = useState(null)
  const [idToken, setIdToken] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const auth = getAuth()
    const unsubscribe = onIdTokenChanged(auth, async (user) => {
      setLoading(true)
      if (user) {
        const token = await user.getIdToken()
        setCurrentUser(user)
        setIdToken(token)
      } else {
        setCurrentUser(null)
        setIdToken(null)
      }
      setLoading(false)
    })

    return () => unsubscribe()
  }, [])

  return {currentUser, idToken, loading}
}

export const useSignOut = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const {resetRecoil} = useResetRecoil()

  return useMutation({
    mutationFn: async () => {
      navigate('/sign-in')
      const auth = getAuth()
      await signOut(auth)
    },
    onSuccess: () => {
      successToast('Signed Out!')
      queryClient.clear()
      resetRecoil()
    },
    onError: () => {
      errorToast('Signing Out Failed.\nPlease try again later.')
    }
  })
}

export const useInitiateGoogleOAuth = () => {
  const oAuthRedirectUri = window.location.origin + '/oauth-callback'
  const googleAuthEndpoint = 'https://accounts.google.com/o/oauth2/v2/auth'
  return useMutation({
    mutationFn: async ({googleClientId}) => {
      const queryParams = new URLSearchParams({
        client_id: googleClientId,
        redirect_uri: oAuthRedirectUri,
        scope:
          'https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.readonly',
        response_type: 'code',
        access_type: 'offline',
        prompt: 'consent'
      })
      window.location = `${googleAuthEndpoint}?${queryParams.toString()}`
    },
    onError: () => {
      errorToast('Connecting With Google Failed.\nPlease try again later.')
    }
  })
}

export const useInitiateMicrosoftOAuth = () => {
  const oAuthRedirectUri = window.location.origin + '/oauth-callback'
  const microsoftAuthEndpoint =
    'https://login.microsoftonline.com/common/oauth2/v2.0/authorize'
  return useMutation({
    mutationFn: async ({microsoftClientId}) => {
      const queryParams = new URLSearchParams({
        client_id: microsoftClientId,
        redirect_uri: oAuthRedirectUri,
        scope:
          'https://graph.microsoft.com/Mail.Send https://graph.microsoft.com/Mail.ReadWrite https://graph.microsoft.com/User.Read offline_access',
        response_type: 'code',
        response_mode: 'query',
        prompt: 'select_account'
      })
      window.location = `${microsoftAuthEndpoint}?${queryParams.toString()}`
    },
    onError: () => {
      errorToast('Connecting With Microsoft Failed.\nPlease try again later.')
    }
  })
}
