import {
  usePostMessageGenerate,
  usePostMessage
} from '../../hooks/server/useAgentServerState.js'

//Atoms
import {useState, useMemo} from 'react'
import {
  Collapse,
  Row,
  Form,
  Stack,
  Modal,
  Col,
  FloatingLabel,
  InputGroup
} from 'react-bootstrap'

import {Button} from '../atoms/Button.js'
import {Spinner} from '../atoms/Spinner.js'

//MOLECULES
import {ActionWithConfirmation} from '../molecules/ActionWithConfirmation.js'

//Utils
import {formatDate, buildMessageThread} from '../../utils/helpers.js'
import {InputWrapper, isTruthy} from '../../utils/hooks.js'

export const MessageModal = ({
  show,
  onHide,
  label,
  emailSubject,
  emailBody,
  updatedAt,
  onDelete,
  onDeleteIsPending
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      size='lg'
    >
      <Modal.Header closeButton>
        <Stack
          direction='vertical'
          gap={0}
        >
          <h4>{label}</h4>
          <Stack
            direction='horizontal'
            gap={1}
          >
            <span className={'material-symbols-outlined color--s-dark'}>
              {'calendar_today'}
            </span>
            <small
              style={{
                fontStyle: 'italic',
                opacity: '0.7'
              }}
            >
              {formatDate(updatedAt)}
            </small>
          </Stack>
        </Stack>
        {onDelete && !onDeleteIsPending && (
          <ActionWithConfirmation
            callback={() => onDelete()}
            disabled={false}
          />
        )}
        {onDeleteIsPending && <Spinner size='sm' />}
      </Modal.Header>
      <Modal.Body>
        <Form className='d-form'>
          {!!emailSubject && (
            <Form.Group as={Col}>
              <FloatingLabel label='Email subject'>
                <Form.Control
                  value={emailSubject || ''}
                  readOnly
                />
              </FloatingLabel>
            </Form.Group>
          )}
          <Form.Group as={Col}>
            <div // NOFLUID it is display html code generated server-side
              dangerouslySetInnerHTML={{__html: emailBody}}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export const MessagePostModal = ({
  show,
  onHide,
  handlePostMessage,
  postMessageStatus
}) => {
  const [messageName, setMessageName] = useState(null)

  return (
    <Modal
      show={show}
      onHide={onHide}
      size='lg'
      centered
    >
      <Modal.Header closeButton>Save Message</Modal.Header>
      <Modal.Body>
        <Form className='d-form'>
          <InputGroup>
            <Form.Control
              value={messageName || ''}
              onChange={(e) => setMessageName(e.target.value)}
              placeholder={'Save Message As...'}
            />
            <Button
              onClick={() => handlePostMessage(messageName)}
              children='Save'
              className='inputFieldButton'
              disabled={postMessageStatus === 'pending' || !messageName}
              isLoading={postMessageStatus === 'pending'}
            />
          </InputGroup>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export const EmailSendModal = ({
  show,
  onHide,
  handlePostEmailSend,
  postCampaignEmailsSendStatus,
  postMessageIsEnabled
}) => {
  const [messageName, setMessageName] = useState(null)

  return (
    <Modal
      show={show}
      onHide={onHide}
      size='lg'
      centered
    >
      <Modal.Header closeButton>Send Message</Modal.Header>
      <Modal.Body>
        {postMessageIsEnabled ? (
          <Form className='d-form'>
            <InputGroup>
              <Form.Control
                value={messageName || ''}
                onChange={(e) => setMessageName(e.target.value)}
                placeholder={'Send Message To...'}
              />
              <Button
                onClick={() => handlePostEmailSend(messageName)}
                children='Send'
                className='inputFieldButton'
                disabled={postCampaignEmailsSendStatus === 'pending' || !messageName}
                isLoading={postCampaignEmailsSendStatus === 'pending'}
              />
            </InputGroup>
          </Form>
        ) : (
          <p>
            Something went wrong when connecting your email. Please make sure your email
            account is connected on the profile page.
          </p>
        )}
      </Modal.Body>
    </Modal>
  )
}

export const GenerateMessageModal = ({
  activeMessageIndex,
  enrichmentCompany,
  enrichmentPerson,
  handleMessageChange,
  inputMessages,
  isFollowUpMessage,
  messageInstructions,
  messageInstructionsFetchStatus,
  messages,
  onHide,
  show
}) => {
  const {mutate: postMessageGenerate, status: postMessageGenerateStatus} =
    usePostMessageGenerate()
  const {mutate: postMessage, status: postMessageStatus} = usePostMessage()

  const [selectedMessageInstructionsId, setSelectedMessageInstructionsId] =
    useState(null)
  const [messageName, setMessageName] = useState(null)
  const [generatedMessage, setGeneratedMessage] = useState(null)

  const selectedMessageInstructions = messageInstructions.find(
    (mi) => mi.message_instructions_id === selectedMessageInstructionsId
  )
  const initialMessageInstructions = useMemo(
    () =>
      messageInstructions?.filter((m) => m.outreach_stage === 'initial_message') || [],
    [messageInstructions]
  )
  const followUpMessageInstructions = useMemo(
    () =>
      messageInstructions?.filter((m) => m.outreach_stage === 'follow_up_message') ||
      [],
    [messageInstructions]
  )
  const instructionsToDisplay = isFollowUpMessage
    ? followUpMessageInstructions
    : initialMessageInstructions

  const handlePostMessage = (label) => {
    postMessage(
      {
        payload: {
          language: selectedMessageInstructions?.language,
          temperature: selectedMessageInstructions?.temperature,
          model_name: selectedMessageInstructions?.model_name,
          outreach_method: selectedMessageInstructions?.outreach_method,
          outreach_stage: selectedMessageInstructions?.outreach_stage,
          sender_employer_name: selectedMessageInstructions?.company_name,
          sender_first_name: selectedMessageInstructions?.first_name,
          sender_intention: selectedMessageInstructions?.intention,
          sender_last_name: selectedMessageInstructions?.last_name,
          sender_preferences: selectedMessageInstructions?.message_preferences,
          sender_title: selectedMessageInstructions?.title,
          sender_value_proposition: selectedMessageInstructions?.value_proposition,
          recipient_summary: enrichmentPerson?.cleaned_summary,
          recipient_employer_summary: enrichmentCompany?.cleaned_summary,
          message_body_raw: generatedMessage?.bodyRaw,
          message_body: generatedMessage?.body,
          message_header: generatedMessage?.header,
          message_signature: selectedMessageInstructions?.message_signature,
          label: label
        }
      },
      {
        onSuccess: (response) => {
          handleMessageChange(activeMessageIndex, response.message_id)
          onHide()
        }
      }
    )
  }

  const handlePostMessageGenerate = () => {
    let payload = {
      language: selectedMessageInstructions?.language,
      outreach_method: selectedMessageInstructions?.outreach_method,
      outreach_stage: selectedMessageInstructions?.outreach_stage,
      sender_employer_name: selectedMessageInstructions?.company_name,
      sender_first_name: selectedMessageInstructions?.first_name,
      sender_intention: selectedMessageInstructions?.intention,
      sender_last_name: selectedMessageInstructions?.last_name,
      sender_preferences: selectedMessageInstructions?.message_preferences,
      sender_title: selectedMessageInstructions?.title,
      sender_value_proposition: selectedMessageInstructions?.value_proposition,
      recipient_summary: enrichmentPerson?.cleaned_summary,
      recipient_employer_summary: enrichmentCompany?.cleaned_summary,
      message_signature: selectedMessageInstructions?.message_signature,
      temperature: selectedMessageInstructions?.temperature,
      model_name: selectedMessageInstructions?.model_name
    }
    if (selectedMessageInstructions?.outreach_stage === 'follow_up_message') {
      payload.message_thread = buildMessageThread({
        inputMessageIds: inputMessages.slice(0, activeMessageIndex),
        messages: messages,
        sender: selectedMessageInstructions?.first_name,
        recipient: enrichmentPerson?.first_name
      })
    }

    postMessageGenerate(
      {
        payload: payload
      },
      {
        onSuccess: (response) => {
          setGeneratedMessage({
            header: response.message_header,
            body: response.message_body,
            bodyRaw: response.message_body_raw
          })
        }
      }
    )
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      size='lg'
      centered
    >
      <Modal.Header closeButton>Generate Message</Modal.Header>
      <Modal.Body>
        <Form className='d-form'>
          <Form.Group as={Row}>
            <InputGroup as={Col}>
              <InputWrapper isLoading={messageInstructionsFetchStatus === 'fetching'}>
                <FloatingLabel label='Select Message Instructions'>
                  <Form.Select
                    value={selectedMessageInstructionsId || 'default'}
                    onChange={(e) => setSelectedMessageInstructionsId(e.target.value)}
                  >
                    {instructionsToDisplay?.map((i) => (
                      <option
                        key={i.label}
                        value={i.message_instructions_id}
                      >
                        {i.label}
                      </option>
                    ))}
                    <option
                      key={'default'}
                      value={'default'}
                    >
                      Select an option
                    </option>
                  </Form.Select>
                </FloatingLabel>
              </InputWrapper>
            </InputGroup>

            <Col
              xs={'auto'}
              className='d-flex align-items-center justify-content-center'
              style={{marginLeft: '1rem'}}
            >
              <Button
                children={'Generate'}
                isLoading={postMessageGenerateStatus === 'pending'}
                disabled={
                  !selectedMessageInstructionsId ||
                  selectedMessageInstructionsId === 'default'
                }
                disabledText={'Please select a valid option.'}
                onClick={() => handlePostMessageGenerate()}
              />
            </Col>
          </Form.Group>
        </Form>

        <Collapse in={generatedMessage && postMessageGenerateStatus !== 'pending'}>
          <div>
            <hr />
            <div // NOFLUID it is display html code generated server-side
              dangerouslySetInnerHTML={{__html: generatedMessage?.body}}
            />
          </div>
        </Collapse>

        <Collapse in={generatedMessage && postMessageGenerateStatus !== 'pending'}>
          <div>
            <hr />
            <Form className='d-form'>
              <Form.Group as={Row}>
                <Col
                  xs={'auto'}
                  style={{paddingRight: '2rem'}}
                >
                  <Form.Label as={Row}>Like it?</Form.Label>
                  <Form.Text
                    muted
                    as={Row}
                  >
                    Then use it as part of the conversation
                  </Form.Text>
                </Col>
                <InputGroup as={Col}>
                  <Form.Control
                    value={messageName || ''}
                    onChange={(e) => setMessageName(e.target.value)}
                    placeholder={'Save Message As...'}
                  />
                  <Button
                    onClick={() => handlePostMessage(messageName)}
                    children='Save'
                    className='inputFieldButton'
                    disabled={!isTruthy(messageName)}
                    isLoading={
                      postMessageGenerateStatus === 'pending' ||
                      postMessageStatus === 'pending'
                    }
                  />
                </InputGroup>
              </Form.Group>
            </Form>
          </div>
        </Collapse>
      </Modal.Body>
    </Modal>
  )
}

export const LoadMessageModal = ({
  show,
  onHide,
  messages,
  messagesFetchStatus,
  activeMessageIndex,
  handleMessageChange
}) => {
  const [selectedMessage, setSelectedMessage] = useState(null)

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      size='md'
    >
      <Modal.Header closeButton>Select Message To Load</Modal.Header>
      <Modal.Body>
        <Form className='d-form'>
          <Form.Group as={Row}>
            <InputGroup as={Col}>
              <InputWrapper isLoading={messagesFetchStatus === 'fetching'}>
                <FloatingLabel label='Select Message'>
                  <Form.Select
                    value={selectedMessage || 'default'}
                    onChange={(e) => setSelectedMessage(e?.target?.value)}
                  >
                    {messages?.map((i) => (
                      <option
                        key={i.message_id}
                        value={i.message_id}
                      >
                        {i.label}
                      </option>
                    ))}
                    <option
                      key={'default'}
                      value={'default'}
                    >
                      Select an option
                    </option>
                  </Form.Select>
                </FloatingLabel>
              </InputWrapper>
            </InputGroup>

            <Col
              xs={'auto'}
              className='d-flex align-items-center justify-content-center'
              style={{marginLeft: '1rem'}}
            >
              <Button
                children={'Load'}
                disabled={!selectedMessage || selectedMessage === 'default'}
                disabledText={'Please select a valid option.'}
                onClick={() => {
                  handleMessageChange(activeMessageIndex, selectedMessage)
                  onHide()
                }}
              />
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
