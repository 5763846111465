//Atoms
import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {Paper} from '@mui/material'
import {
  Collapse,
  InputGroup,
  Form,
  Stack,
  Modal,
  Col,
  Badge,
  Row,
  FloatingLabel
} from 'react-bootstrap'
import {Button} from '../atoms/Button.js'
import {InputWrapper, isTruthy} from '../../utils/hooks.js'

//MOLECULES
import {ActionWithConfirmation} from '../molecules/ActionWithConfirmation.js'
import {SelectCreateOptions} from '../molecules/SelectCreateOptions.js'

//Utils
import {
  buildCompanySearchFilters,
  buildPersonSearchFilters,
  formatDate
} from '../../utils/helpers.js'
import {MatchesFoundRounder} from '../../utils/calculations.js'

const FilterPaper = ({keyName, values, operator, sign}) => {
  const formatKey = (key) => {
    let formatted = key.replace(/_/g, ' ')
    formatted = formatted.replace(/([A-Z])/g, ' $1')
    formatted = formatted.replace(/\b\w/g, (char) => char.toUpperCase())
    return formatted
  }

  const displayValues = (values) => {
    return (
      <span
        style={{
          display: 'flex',
          flexWrap: 'nowrap',
          overflowX: 'auto',
          overflowY: 'hidden',
          margin: '0 0 0 0.25rem'
        }}
      >
        {values?.map((value, index) => (
          <Badge
            key={index}
            style={{
              whiteSpace: 'nowrap',
              margin: '0 0.15rem 0rem 0rem'
            }}
          >
            {value}
          </Badge>
        ))}
      </span>
    )
  }

  return (
    <Paper
      variant='outlined'
      className='background--s-light'
      style={{
        maxWidth: '15rem',
        margin: '0.5rem 1rem 0.5rem 1rem',
        padding: '0.5rem'
      }}
    >
      <p style={{margin: '0 0 0.25rem 0'}}>
        <strong>{formatKey(keyName)}</strong>
      </p>
      <p
        style={{margin: 0}}
        className='d-flex align-items-center'
      >
        Values: {displayValues(values)}
      </p>
      {operator && <p style={{margin: 0}}>Combination: {formatKey(operator)}</p>}
      {sign && (
        <p style={{margin: 0}}>
          Selection: {sign === 'equals' ? 'Include' : 'Exclude'}
        </p>
      )}
    </Paper>
  )
}

export const SaveAudienceModal = ({
  resultsLoaded,
  audienceMetaDataAtom,
  audiences,
  audiencesFetchStatus,
  companySearchIsEnabled,
  companySearchSettingsAtom,
  onHide,
  patchAudience,
  patchAudienceStatus,
  personSearchIsEnabled,
  personSearchSettingsAtom,
  postAudience,
  postAudienceStatus,
  setAudienceMetaDataKey,
  show
}) => {
  const navigate = useNavigate()
  const [showCreateFollowUp, setShowCreateFollowUp] = useState(false)

  const audienceOptions = audiences?.map((a) => ({
    label: a?.label,
    value: a?.audience_id
  }))
  const audienceExist = audiences?.some(
    (audience) => audience?.label === audienceMetaDataAtom?.label
  )
  const selectedAudience = audiences?.find(
    (audience) => audience?.audience_id === audienceMetaDataAtom?.audienceId
  )

  const companyFilters = Object.entries(
    buildCompanySearchFilters(companySearchSettingsAtom)
  ).filter(([key, {values}]) => isTruthy(values))

  const personFilters = Object.entries(
    buildPersonSearchFilters(personSearchSettingsAtom)
  ).filter(
    ([key, values]) =>
      isTruthy(values) && key !== 'organization_linkedin_public_identifiers'
  )
  let errorMessage = ''
  if (isTruthy(personSearchIsEnabled)) {
    errorMessage =
      'Seems you have updated your employee search filters without conducting a search. Please make sure you are happy with the search results before proceeding.'
  }
  if (isTruthy(companySearchIsEnabled)) {
    errorMessage =
      'Seems you have updated your company search filters without conducting a search. Please make sure you are happy with the search results before proceeding.'
  }
  if (!isTruthy(audienceMetaDataAtom?.peopleInAudience)) {
    errorMessage =
      'Seems there are no employees in your audience. Please make sure to conduct a valid search before saving your audience.'
  }
  if (!isTruthy(audienceMetaDataAtom?.companiesInAudience)) {
    errorMessage =
      'Seems there are no companies in your audience. Please make sure to conduct a valid search before saving your audience.'
  }
  if (!isTruthy(resultsLoaded)) {
    errorMessage =
      'Results are still being calculated. Please wait before saving your audience.'
  }

  useEffect(() => {
    if (patchAudienceStatus === 'success' || postAudienceStatus === 'success') {
      setShowCreateFollowUp(true)
    }
  }, [patchAudienceStatus, postAudienceStatus])

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered={
        (!isTruthy(errorMessage) && isTruthy(companyFilters)) || isTruthy(personFilters)
          ? false
          : true
      }
      size={isTruthy(errorMessage) ? 'lg' : 'xl'}
    >
      <Modal.Header closeButton>Save Audience</Modal.Header>
      <Modal.Body className='d-flex flex-column align-items-start '>
        {isTruthy(errorMessage) ? (
          <p style={{textAlign: 'center'}}>{errorMessage}</p>
        ) : (
          <>
            {isTruthy(companyFilters) && (
              <>
                <h6>{`Company Search Filters - ${MatchesFoundRounder(
                  audienceMetaDataAtom?.companiesInAudience
                )} companies matching`}</h6>
                <div
                  className='d-flex flex-wrap align-items-start'
                  style={{padding: '0.5rem 0 1rem 0'}}
                >
                  {companyFilters.map(([key, filter]) => (
                    <FilterPaper
                      key={key}
                      keyName={key}
                      values={filter.values}
                      operator={filter.operator}
                      sign={filter.sign}
                    />
                  ))}
                </div>
              </>
            )}

            {isTruthy(personFilters) && (
              <>
                <h6>{`Employee Search Filters - ${MatchesFoundRounder(
                  audienceMetaDataAtom?.peopleInAudience
                )} employees matching`}</h6>

                <div
                  className='d-flex flex-wrap align-items-start'
                  style={{padding: '0.5rem 0 1rem 0'}}
                >
                  {personFilters.map(([key, values]) => (
                    <FilterPaper
                      key={key}
                      keyName={key}
                      values={values}
                    />
                  ))}
                </div>
              </>
            )}

            <Form
              className='d-form'
              style={{placeSelf: 'center', width: '100%'}}
            >
              <InputGroup as={Row}>
                <Col>
                  <SelectCreateOptions
                    options={audienceOptions}
                    optionsLoading={audiencesFetchStatus === 'fetching'}
                    onChange={(e) => {
                      setAudienceMetaDataKey('label', e?.label)
                      setAudienceMetaDataKey('audienceId', e?.value)
                    }}
                    value={{
                      value: audienceMetaDataAtom?.audienceId,
                      label: audienceMetaDataAtom?.label
                    }}
                  />
                </Col>
                <Form.Group
                  as={Col}
                  xs='auto'
                >
                  {audienceExist ? (
                    <Button
                      children={'Update'}
                      onClick={patchAudience}
                      isLoading={patchAudienceStatus === 'pending'}
                      disabled={isTruthy(selectedAudience?.used_in_campaign)}
                      disabledText={
                        'Updating of audiences that are already in use by a campaign is not supported.'
                      }
                    />
                  ) : (
                    <Button
                      children={'Save'}
                      disabledText={
                        audienceMetaDataAtom?.label?.length < 1 ||
                        !audienceMetaDataAtom?.label
                          ? 'Saving your instructions requires providing a name'
                          : null
                      }
                      disabled={
                        audienceMetaDataAtom?.label?.length < 1 ||
                        !audienceMetaDataAtom?.label
                      }
                      onClick={postAudience}
                      isLoading={postAudienceStatus === 'pending'}
                    />
                  )}
                </Form.Group>
              </InputGroup>
            </Form>
            <Collapse in={showCreateFollowUp}>
              <p
                style={{
                  textAlign: 'center',
                  width: '65%',
                  placeSelf: 'center',
                  paddingTop: '0.5rem'
                }}
              >
                {'Would you like to create AI instructions for generating messages '}
                <span
                  className='clickable transition-fast t-underline--accent color--accent'
                  onClick={() => {
                    onHide()
                    navigate('/message-builder')
                  }}
                >
                  {'next?'}
                </span>
                {' Or are you already ready to set up a '}
                <span
                  className='clickable transition-fast t-underline--accent color--accent'
                  onClick={() => {
                    onHide()
                    navigate('/campaign-builder')
                  }}
                >
                  {'campaign?'}
                </span>
              </p>
            </Collapse>
          </>
        )}
      </Modal.Body>
    </Modal>
  )
}

export const LoadAudienceModal = ({
  show,
  onHide,
  audiences,
  audiencesFetchStatus,
  handleLoadAudience
}) => {
  const [selectedAudienceId, setSelectedAudienceId] = useState(null)

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
    >
      <Modal.Header closeButton>Select Audience To Load</Modal.Header>
      <Modal.Body>
        <Form className='d-form'>
          <Form.Group as={Row}>
            <InputGroup as={Col}>
              <InputWrapper isLoading={audiencesFetchStatus === 'fetching'}>
                <FloatingLabel label='Select Audience'>
                  <Form.Select
                    value={selectedAudienceId || 'default'}
                    onChange={(e) => setSelectedAudienceId(e?.target?.value)}
                  >
                    {audiences?.map((i) => (
                      <option
                        key={i.audience_id}
                        value={i.audience_id}
                      >
                        {i.label}
                      </option>
                    ))}
                    <option
                      key={'default'}
                      value={'default'}
                    >
                      Select an option
                    </option>
                  </Form.Select>
                </FloatingLabel>
              </InputWrapper>
            </InputGroup>

            <Col
              xs={'auto'}
              className='d-flex align-items-center justify-content-center'
              style={{marginLeft: '1rem'}}
            >
              <Button
                children={'Load'}
                disabled={!selectedAudienceId || selectedAudienceId === 'default'}
                disabledText={'Please select a valid option.'}
                onClick={() => {
                  handleLoadAudience(selectedAudienceId)
                  onHide()
                }}
              />
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export const AudienceModal = ({
  show,
  onHide,
  audienceMetaDataAtom,
  companySearchSettingsAtom,
  personSearchSettingsAtom,
  onDelete,
  onEdit
}) => {
  const companyFilters = Object.entries(
    buildCompanySearchFilters(companySearchSettingsAtom)
  ).filter(([key, {values}]) => isTruthy(values))

  const personFilters = Object.entries(
    buildPersonSearchFilters(personSearchSettingsAtom)
  ).filter(
    ([key, values]) =>
      isTruthy(values) && key !== 'organization_linkedin_public_identifiers'
  )

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      size='lg'
    >
      <Modal.Header closeButton>
        <Stack
          direction='vertical'
          gap={0}
        >
          <h4>{audienceMetaDataAtom?.label}</h4>
          <Stack
            direction='horizontal'
            gap={1}
          >
            <span className={'material-symbols-outlined color--s-dark'}>
              {'calendar_today'}
            </span>

            <small style={{fontStyle: 'italic', opacity: '0.7'}}>
              {formatDate(audienceMetaDataAtom?.updatedAt)}
            </small>
          </Stack>
        </Stack>
        {onEdit && (
          <span
            className='material-symbols-outlined color--p-dark clickable transition-fast t-scale--small t-color--accent'
            onClick={onEdit}
          >
            {'edit_square'}
          </span>
        )}
        {audienceMetaDataAtom?.usedInCampaign && (
          <span
            title='Audience is used in a campaign'
            style={{color: 'green'}}
            className='material-symbols-outlined'
          >
            {'campaign'}
          </span>
        )}
        {onDelete && (
          <ActionWithConfirmation
            callback={() => onDelete()}
            disabled={audienceMetaDataAtom?.usedInCampaign}
          />
        )}
      </Modal.Header>
      <Modal.Body>
        {isTruthy(companyFilters) && (
          <>
            <h6>{`Company Search Filters - ${MatchesFoundRounder(
              audienceMetaDataAtom?.companiesInAudience
            )} companies matching`}</h6>
            <div
              className='d-flex flex-wrap align-items-start'
              style={{padding: '0.5rem 0 1rem 0'}}
            >
              {companyFilters.map(([key, filter]) => (
                <FilterPaper
                  key={key}
                  keyName={key}
                  values={filter.values}
                  operator={filter.operator}
                  sign={filter.sign}
                />
              ))}
            </div>
          </>
        )}

        {isTruthy(personFilters) && (
          <>
            <h6>{`Employee Search Filters - ${MatchesFoundRounder(
              audienceMetaDataAtom?.peopleInAudience
            )} employees matching`}</h6>

            <div
              className='d-flex flex-wrap align-items-start'
              style={{padding: '0.5rem 0 1rem 0'}}
            >
              {personFilters.map(([key, values]) => (
                <FilterPaper
                  key={key}
                  keyName={key}
                  values={values}
                />
              ))}
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  )
}
