export const MatchesFoundRounder = (number) => {
  number = parseInt(number, 10)

  if (number >= 10000000) {
    return '10M+'
  } else if (number >= 1000000) {
    const millions = Math.floor(number / 500000) * 500000
    const remainder = number % 500000
    const rounded = remainder >= 250000 ? millions + 500000 : millions
    return `${rounded / 1000000}M+`
  } else if (number >= 100000) {
    const hundredThousands = Math.floor(number / 50000) * 50000
    const remainder = number % 50000
    const rounded = remainder >= 25000 ? hundredThousands + 50000 : hundredThousands
    return `${rounded / 1000}K+`
  } else if (number >= 10000) {
    const tenThousands = Math.floor(number / 5000) * 5000
    const remainder = number % 5000
    const rounded = remainder >= 2500 ? tenThousands + 5000 : tenThousands
    return `${rounded}+`
  } else if (number >= 1000) {
    const thousands = Math.floor(number / 500) * 500
    const remainder = number % 500
    const rounded = remainder >= 250 ? thousands + 500 : thousands
    return `${rounded}+`
  } else if (number >= 100) {
    const hundreds = Math.floor(number / 50) * 50
    const remainder = number % 50
    const rounded = remainder >= 25 ? hundreds + 50 : hundreds
    return `${rounded}+`
  } else {
    return `${number}`
  }
}
export function calculateMaxPeopleOutreach({
  maxOutreachMessages,
  maxOutreachPerPerson
}) {
  maxOutreachMessages = parseInt(maxOutreachMessages, 10)
  maxOutreachPerPerson = parseInt(maxOutreachPerPerson, 10)
  return Math.floor(maxOutreachMessages / maxOutreachPerPerson)
}
export function calculatePeoplePerCompany({peopleInAudience, companiesInAudience}) {
  peopleInAudience = parseInt(peopleInAudience, 10)
  companiesInAudience = parseInt(companiesInAudience, 10)
  return peopleInAudience / companiesInAudience
}

export function calculateCompaniesToReach({
  maxOutreachPeoplePerCompany,
  maxPeopleOutreach,
  peoplePerCompany,
  companiesInAudience
}) {
  maxOutreachPeoplePerCompany = parseInt(maxOutreachPeoplePerCompany, 10)
  maxPeopleOutreach = parseInt(maxPeopleOutreach, 10)
  peoplePerCompany = parseFloat(peoplePerCompany)
  companiesInAudience = parseInt(companiesInAudience, 10)

  const effectivePeopleOutreachPerCompany = Math.min(
    maxOutreachPeoplePerCompany,
    peoplePerCompany
  )

  const potentialCompaniesReached = Math.floor(
    maxPeopleOutreach / effectivePeopleOutreachPerCompany
  )

  return Math.min(
    potentialCompaniesReached,
    companiesInAudience,
    Math.floor(companiesInAudience * peoplePerCompany)
  )
}

export function calculatePeopleToReach({
  companiesToReach,
  maxOutreachPeoplePerCompany,
  maxPeopleOutreach,
  peopleInAudience
}) {
  companiesToReach = parseInt(companiesToReach, 10)
  maxOutreachPeoplePerCompany = parseInt(maxOutreachPeoplePerCompany, 10)
  maxPeopleOutreach = parseInt(maxPeopleOutreach, 10)
  peopleInAudience = parseInt(peopleInAudience, 10)

  return Math.min(
    companiesToReach * maxOutreachPeoplePerCompany,
    maxPeopleOutreach,
    peopleInAudience
  )
}

export function calculateLogDiscrepancyRatio({
  maxOutreachPeoplePerCompany,
  peoplePerCompany
}) {
  maxOutreachPeoplePerCompany = parseInt(maxOutreachPeoplePerCompany, 10)
  peoplePerCompany = parseFloat(peoplePerCompany)
  return Math.log(maxOutreachPeoplePerCompany + 1) - Math.log(peoplePerCompany + 1)
}

export function checkMaxPeopleOutreach({
  maxPeopleOutreach,
  maxOutreachMessagesPerDay,
  maxOutreachPeoplePerCompany
}) {
  maxPeopleOutreach = parseInt(maxPeopleOutreach, 10)
  maxOutreachMessagesPerDay = parseInt(maxOutreachMessagesPerDay, 10)
  maxOutreachPeoplePerCompany = parseInt(maxOutreachPeoplePerCompany, 10)

  if (maxPeopleOutreach < 1 || maxPeopleOutreach > 100000) {
    return 'Total outreach must be a value between 1 and 100,000'
  }

  return null
}

export function checkMaxPeopleOutreachPerDay({maxOutreachMessagesPerDay}) {
  maxOutreachMessagesPerDay = parseInt(maxOutreachMessagesPerDay, 10)

  if (maxOutreachMessagesPerDay < 1 || maxOutreachMessagesPerDay > 100) {
    return 'Outreach per day must a value between 1 and 100'
  }

  return null
}

export function checkMaxPeopleOutreachPerCompany({maxOutreachPeoplePerCompany}) {
  maxOutreachPeoplePerCompany = parseInt(maxOutreachPeoplePerCompany, 10)

  if (maxOutreachPeoplePerCompany < 1 || maxOutreachPeoplePerCompany > 20) {
    return 'Outreach per company must be a value between 1 and 20'
  }

  return null
}

export function checkCompaniesReach({companiesToReach, companiesInAudience}) {
  companiesToReach = parseInt(companiesToReach, 10)
  companiesInAudience = parseInt(companiesInAudience, 10)

  if (companiesToReach / companiesInAudience < 0.25) {
    return 'With the current settings you will reach less than 25% of companies in your audience'
  }

  return null
}

export function checkPeopleReach({
  peopleToReach,
  peopleInAudience,
  logDiscrepancyRatio
}) {
  peopleToReach = parseInt(peopleToReach, 10)
  peopleInAudience = parseInt(peopleInAudience, 10)
  logDiscrepancyRatio = parseFloat(logDiscrepancyRatio)

  if (peopleToReach / peopleInAudience < 0.2) {
    return 'With the current settings you will reach less than 20% of the employees in your audience'
  }

  if (logDiscrepancyRatio < -1.5) {
    return 'With the current settings you will reach too few employees per company'
  }

  return null
}

export function checkAudiencePeople({peopleInAudience, companiesInAudience}) {
  companiesInAudience = parseInt(companiesInAudience, 10)
  peopleInAudience = parseInt(peopleInAudience, 10)

  if (peopleInAudience > 9999) {
    return 'We only support campaigns with less than 10.000 employees currently'
  }

  if (peopleInAudience / companiesInAudience < 0.2) {
    return 'Your Audience contains too few employees per company. You need at least one employee per five companies on average.'
  }

  return null
}

export function checkAudienceCompanies({companiesInAudience}) {
  companiesInAudience = parseInt(companiesInAudience, 10)

  if (companiesInAudience > 9999) {
    return 'We only support campaigns with less than 10.000 companies currently'
  }

  return null
}
