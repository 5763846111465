import {
  useQuery,
  useMutation,
  useQueryClient,
  useInfiniteQuery
} from '@tanstack/react-query'
import * as audienceApi from '../../services/audienceApi.js'
import {errorToast, successToast} from '../../components/atoms/Toasts.js'
import * as CS from '../../hooks/client/useAudiencesClientState.js'

export const usePostAudience = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({payload}) => audienceApi.postAudience({payload}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['audiences']})
      successToast('Audience Saved!')
    },
    onError: () => {
      errorToast('Saving Audience Failed.\nPlease try again later.')
    }
  })
}
export const useGetAudiences = ({params} = {}) => {
  return useQuery({
    queryKey: ['audiences', params],
    queryFn: () => audienceApi.getAudiences({params}),
    staleTime: 8 * 60 * 60 * 1000,
    cacheTime: 24 * 60 * 60 * 1000,
    meta: {
      errorMessage: 'Fetching Audiences Failed.\nPlease try again later.'
    }
  })
}
export const usePatchAudience = ({showToast = true} = {}) => {
  const queryClient = useQueryClient()
  const {audienceMetaDataAtom, setAudienceMetaDataKey} = CS.useAudienceMetaData()

  return useMutation({
    mutationFn: ({params, payload}) => audienceApi.patchAudience({params, payload}),
    onSuccess: (r) => {
      queryClient.invalidateQueries({queryKey: ['audiences']})
      if (showToast) {
        successToast('Audience Updated!')
      }
      if (audienceMetaDataAtom?.audienceId === r.audience_id) {
        setAudienceMetaDataKey('usedInCampaign', r.used_in_campaign)
      }
    },
    onError: () => {
      errorToast('Updating Audience Failed.\nPlease try again later.')
    }
  })
}
export const useDeleteAudience = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({params}) => audienceApi.deleteAudience({params}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['audiences']})
      successToast('Audience Deleted!')
    },
    onError: () => {
      errorToast('Deleting Audience Failed.\nPlease try again later.')
    }
  })
}

export const useGetSearchFilterOptions = ({params, enabled = true}) => {
  return useQuery({
    queryKey: ['searchFilterOptions', params],
    queryFn: () => audienceApi.getSearchFilterOptions({params}),
    enabled: !!enabled,
    staleTime: 6 * 24 * 60 * 60 * 1000,
    cacheTime: 6 * 24 * 60 * 60 * 1000,
    meta: {
      errorMessage: 'Fetching Filters Failed.\nPlease try again later.'
    }
  })
}

export const usePostSearchPeople = ({params, payload, enabled = true}) => {
  return useInfiniteQuery({
    queryKey: ['audiencePeople', payload, params],
    queryFn: ({pageParam = 0}) =>
      audienceApi.postSearchPeople({
        params: {...params, page: pageParam},
        payload
      }),
    enabled: !!enabled,
    staleTime: 6 * 24 * 60 * 60 * 1000,
    cacheTime: 6 * 24 * 60 * 60 * 1000,
    getNextPageParam: (lastPage, allPages) => {
      const currentPage = allPages.length - 1
      const numberOfPages = lastPage.total_search_results / 10 - 1
      return currentPage < numberOfPages ? currentPage + 1 : undefined
    },
    initialPageParam: 0,
    meta: {
      errorMessage: 'Fetching Employees Failed.\nPlease try again later.'
    }
  })
}

export const usePostSearchEmployeesCount = ({params, payload, enabled = true}) => {
  return useQuery({
    queryKey: ['audienceEmployeesCount', payload, params],
    queryFn: () => audienceApi.postSearchEmployeesCount({payload, params}),
    enabled: !!enabled,
    staleTime: 6 * 24 * 60 * 60 * 1000,
    cacheTime: 6 * 24 * 60 * 60 * 1000,
    meta: {
      errorMessage:
        'Estimating amount of employees in audience failed.\nPlease try again later.'
    }
  })
}

export const usePostSearchCompanies = ({payload, params, enabled = true}) => {
  return useInfiniteQuery({
    queryKey: ['audienceCompanies', payload, params.size],
    queryFn: ({pageParam = 1}) =>
      audienceApi.postSearchCompanies({
        params: {...params, page: pageParam},
        payload
      }),
    enabled: !!enabled,
    staleTime: 6 * 24 * 60 * 60 * 1000,
    cacheTime: 6 * 24 * 60 * 60 * 1000,
    getNextPageParam: (lastPage) => {
      const currentPage = lastPage.meta.currentPage
      const numberOfPages = lastPage.meta.lastPage
      return currentPage < numberOfPages ? currentPage + 1 : undefined
    },
    initialPageParam: 1,
    meta: {
      errorMessage: 'Fetching Companies Failed.\nPlease try again later.'
    }
  })
}

export const usePostSearchCompaniesCount = ({payload, enabled = true}) => {
  return useQuery({
    queryKey: ['audienceCompaniesCount', payload],
    queryFn: () => audienceApi.postSearchCompaniesCount({payload}),
    enabled: !!enabled,
    staleTime: 6 * 24 * 60 * 60 * 1000,
    cacheTime: 6 * 24 * 60 * 60 * 1000,
    meta: {
      errorMessage:
        'Estimating amount of companies in audience failed.\nPlease try again later.'
    }
  })
}

export const useGetEnrichmentPerson = ({params, enabled = true}) => {
  return useQuery({
    queryKey: ['personEnrichment', params],
    queryFn: () => audienceApi.getEnrichmentPerson({params}),
    enabled: !!enabled,
    staleTime: 24 * 60 * 60 * 1000,
    cacheTime: 6 * 24 * 60 * 60 * 1000,
    meta: {
      errorMessage: 'Fetching Person Information Failed.\nPlease try again later.'
    }
  })
}

export const useGetEnrichmentCompany = ({params, enabled = false}) => {
  return useQuery({
    queryKey: ['companyEnrichment', params],
    queryFn: () => audienceApi.getEnrichmentCompany({params}),
    enabled: !!enabled,
    staleTime: 24 * 60 * 60 * 1000,
    cacheTime: 6 * 24 * 60 * 60 * 1000,
    meta: {
      errorMessage: 'Fetching Company Information Failed.\nPlease try again later.'
    }
  })
}
